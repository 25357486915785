import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <h1 className="text-5xl md:text-6xl lg:text-7xl font-serif text-center mt-16">404: Not Found</h1>
    <p className="text-lg md:text-xl lg:text-2xl max-w-3xl pt-2 md:pt-4 text-center mx-auto mb-20">You just hit a route that doesn&#39;t exist... the sadness.</p>
  </Layout>
)

export default NotFoundPage
